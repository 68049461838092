//TODO LINT
/* eslint-disable */
import { getDays, getHours } from './dateAdapter'
import moment from 'moment'
const cornerUrl = process.env.REACT_APP_CORNER_URL
import {
  ClientDelivery,
  ClientPickup,
  ROUTIFIC_ERROR_ENUM,
  tourType,
} from './values'
const colorArray = [
  '#2979ff',
  '#ff1744',
  '#76ff03',
  '#ff00ff',
  '#ff6d00',
  '#ffff00',
  '#00e5ff',
  '#ff5252',
  '#00c853',
  '#aa00ff',
  '#ff9100',
  '#ffd600',
  '#304ffe',
  '#d50000',
  '#b2ff59',
  '#e040fb',
  '#ffab40',
  '#ffff00',
  '#00b8d4',
  '#d500f9',
  '#6200ea',
]
// const reorder = (list, startIndex, endIndex) => {
//   const result = Array.from(list)
//   const [removed] = result.splice(startIndex, 1)
//   result.splice(endIndex, 0, removed)

//   return result
// }

// const dragBetweenTables = (sourceList, destList, startIndex, endIndex) => {
//   const source = Array.from(sourceList)
//   const destination = Array.from(destList)
//   const [removed] = source.splice(startIndex, 1)
//   console.log('testing changed item', removed)
//   const twinIndex = source.findIndex(
//     (visit) =>
//       visit.location_id === removed.location_id && visit.type !== removed.type
//   )
//   const [twin] = source.splice(twinIndex, 1)
//   const pair = [removed, twin]
//   pair.forEach((el) => {
//     if (el.type === 'dropoff') {
//       destination.splice(endIndex, 0, el)
//     } else if (el.type === 'pickup') {
//       destination.splice(1, 0, el)
//     }
//   })
//   //debugger
//   return { source, destination }
// }

const reorderMultiple = (list, startIndex, endIndex, isOnePickUp) => {
  let result = Array.from(list)
  let course = result[startIndex]
  if (!result[startIndex].checked) {
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
  } else {
    const listChecked = result.filter((rslt) => rslt.checked)
    let indexEnd =
      endIndex > 1
        ? (isOnePickUp && endIndex === list.length / 2) ||
          endIndex <= listChecked.length
          ? endIndex
          : endIndex - listChecked.length + 1
        : 1
    listChecked.forEach((list) => {
      result.map((ele, pos) => {
        if (list.location_id === ele.location_id && list.type === ele.type)
          result.splice(pos, 1)
      })
    })

    listChecked
      .sort((a, b) => a.order - b.order)
      .map((Visit) => {
        result.splice(indexEnd, 0, Visit)
        indexEnd++
      })
  }
  let validator = true
  if (isOnePickUp === tourType.ONE_PICK_UP) {
    const newResult = [...result]
    validator = validateOrderCheck(newResult)
    result = sortMarkers(result, course)
  }
  return { newMarkerList: result, validator: validator }
}

const elementExist = (tab1, element) => {
  let nbOccurence = 0
  tab1.map((el) => {
    if (el.location_id == element.location_id) nbOccurence++
  })
  return nbOccurence
}

const dragMultipleBetweenTables = (
  sourceList,
  destList,
  startIndex,
  endIndex,
  isOnePickUp
) => {
  let source = Array.from(sourceList)
  let destination = Array.from(destList)
  let course = source[startIndex]
  // drag and drop une seule course sans check
  if (!source[startIndex].checked) {
    const [removed] = source.splice(startIndex, 1)
    const twinIndex = source.findIndex(
      (visit) =>
        visit.location_id === removed.location_id && visit.type !== removed.type
    )
    const [twin] = source.splice(twinIndex, 1)
    const pair = [removed, twin]
    const pickup = pair.filter((el) => el.type === 'pickup')
    destination.splice(endIndex, 0, pickup[0])
    const dropoff = pair.filter((el) => el.type === 'dropoff')
    destination.splice(endIndex + 1, 0, dropoff[0])
  } else {
    const listChecked = source.filter((rslt) => rslt.checked)
    let indexEnd =
      endIndex > 1
        ? endIndex - listChecked.length + 1 < 1
          ? endIndex
          : endIndex - listChecked.length + 1
        : 1

    const listCheckedTwin = listChecked

    // suprimmer twins dans tableau source
    listChecked.forEach((list) => {
      source.map((ele, pos) => {
        if (list.location_id === ele.location_id && list.type === ele.type) {
          source.splice(pos, 1)
        }
      })
    })

    // trouver twins dans tableau source
    source.forEach((el) => {
      if (elementExist(listChecked, el) == 1) {
        listCheckedTwin.push(el)
      }
    })

    // suprimmer twins dans tableau source
    listCheckedTwin.forEach((list) => {
      source.map((ele, pos) => {
        if (list.location_id === ele.location_id && list.type === ele.type) {
          source.splice(pos, 1)
        }
      })
    })

    //ajout les nouveaux elements dans tableau destination
    listCheckedTwin
      .sort((a, b) => a.order - b.order)
      .map((Visit) => {
        destination.splice(indexEnd, 0, Visit)
        indexEnd++
      })
  }
  if (isOnePickUp === tourType.ONE_PICK_UP) {
    destination = sortMarkers(destination, course)
  }
  return { source, destination }
}
export const getToursData = (responseData, deliveryDate, driverTours) => {
  const driversWithColors = []
  const tours = responseData.map((response) => {
    return response.tours.map((tour) => {
      if (!(tour.markers.length === 2 || tour.markers.length === 1)) {
        driversWithColors.push(tour.driver.id)
      }
      const driver = {
        id: tour.driver.id,
        firstName: tour.driver.firstName,
        name: tour.driver.name,
        competences: tour.driver.competences,
        vehiculeType: tour.driver.vehiculeType ?? '-',
        shiftStart: tour.driver.shiftStart.label
          ? tour.driver.shiftStart.label
          : tour.driver.shiftStart
          ? tour.driver.shiftStart
          : undefined,
        shiftEnd: tour.driver.shiftEnd.label
          ? tour.driver.shiftEnd.label
          : tour.driver.shiftEnd
          ? tour.driver.shiftEnd
          : undefined,
      }
      const markers = tour.markers.map((marker) => {
        return {
          ...marker,
          arrival_time: getHours(
            marker.arrival_time ? marker.arrival_time : marker.estimatedArrivalTime
          ),
          finish_time: getHours(
            marker.finish_time ? marker.finish_time : marker.estimatedDepartureTime
          ),
          day: getDays(
            marker.arrival_time ? marker.arrival_time : marker.estimatedArrivalTime
          ),
          estimatedArrivalTime: moment(
            marker.arrival_time ? marker.arrival_time : marker.estimatedArrivalTime
          ).format(),
          estimatedDepartureTime: moment(
            marker.finish_time ? marker.finish_time : marker.estimatedDepartureTime
          ).format(),
          windowEnd: marker.windowEnd ? marker.windowEnd : undefined,
          windowStart: marker.windowStart ? marker.windowStart : undefined,
          secondWindowStart: marker.secondWindowStart
            ? marker.secondWindowStart
            : undefined,
          secondWindowEnd: marker.secondWindowEnd
            ? marker.secondWindowEnd
            : undefined,
          idle_time: marker.idle_time ? marker.idle_time : undefined,
          isManu: marker.isManu ? marker.isManu : undefined,
          courseType: marker?.courseType ? marker?.courseType : undefined,
          salesChanel: marker?.salesChanel,
          keys: marker?.keys,
          nbColis: marker?.nbColis,
          prestaTime: marker?.prestaTime,
          receptionNumber: marker?.location_id,
          codePostale: marker?.codePostale,
          locationType: marker?.locationType,
          checked: false,
          orderGiver: marker?.orderGiver,
          client: marker?.client,
          reason: marker?.reason,
        }
      })
      // driverTours [{ driverId: s.driver.id, ldt: s.ldt }]
      if (driver.id && driverTours && driverTours.length > 0) {
        tour.ldt = driverTours.find((pred) => pred.driverId == driver.id)
          ? driverTours.find((pred) => pred.driverId == driver.id).ldt
          : undefined
      }
      return {
        ...tour,
        color:
          driversWithColors.indexOf(tour.driver) !== -1
            ? colorArray[driversWithColors.indexOf(tour.driver) % colorArray.length]
            : '#000000',
        driver,
        distance: tour.distance,
        totalWeight: tour.tourWeight,
        tourDropoffWeight: tour?.tourDropoffWeight || 0,
        tourPickupWeight: tour?.tourPickupWeight || 0,
        totalVolume: tour.tourVolume,
        totalTime: tour.tourDuration,
        montantPrestataireHT: tour.tourPrice,
        trajetTime: tour.tourTravel,
        stopsNumber: tour?.stopsNumber || 0,
        markers,
        precision: 6,
        deliveryDate: deliveryDate,
        checkedAll: false,
        nbVisitChecked: 0,
        lock: tour?.lock || false,
      }
    })
  })
  console.log('testing after parse', tours)
  return tours
}
//calculate the new generalInfo
// export const getGeneralInfo = (generalInfo, tours, shiftEnd) => {
//   return tours.map((tour, i) => {
//     let totalDistance = 0
//     let totalIdleTime = 0
//     let totalTravelTime = 0
//     let totalVehicleOvertime = 0
//     let totalVisitlateness = 0
//     tour.map((t) => {
//       totalDistance = totalDistance + t.distance
//       totalIdleTime =
//         totalIdleTime +
//         t.markers.reduce((total, m) => (total + m.idle_time ? m.idle_time : 0), 0)
//       totalTravelTime =
//         totalTravelTime +
//         moment(t.markers[t.markers.length - 1].etaFinish).diff(
//           moment(t.markers[0].etaArrival),
//           'minutes'
//         )
//       totalVehicleOvertime =
//         totalVehicleOvertime +
//           moment(t.markers[t.markers.length - 1].etaFinish)
//             .set('hour', shiftEnd)
//             .diff(moment(t.markers[t.markers.length - 1].etaFinish), 'minutes') >
//         0
//           ? moment(t.markers[t.markers.length - 1].etaFinish)
//               .set('hour', shiftEnd)
//               .diff(moment(t.markers[t.markers.length - 1].etaFinish), 'minutes')
//           : 0
//       totalVisitlateness =
//         totalVisitlateness +
//         t.markers.reduce(
//           (total, m) =>
//             total + m.windowEnd &&
//             moment(m.etaArrival).diff(moment(m.windowEnd), 'minutes') > 0
//               ? moment(m.etaArrival).diff(moment(m.windowEnd), 'minutes')
//               : 0,
//           0
//         )
//     })
//     return {
//       nbUnserved: generalInfo[i].nbUnserved  ,
//       precision: generalInfo[i].precision || 6 ,
//       totalDistance: totalDistance,
//       totalIdleTime: totalIdleTime,
//       totalTravelTime: totalTravelTime,
//       totalVehicleOvertime: totalVehicleOvertime,
//       totalVisitlateness: totalVisitlateness,
//       deliveryDate: generalInfo[i].deliveryDate,
//     }
//   })
// }
// parse updated tours to send to polaris MS recalculate API
export const parseUpdatedTours = (updatedTours, isOnePickUp) => {
  return updatedTours[0].map((t) => ({
    driver: t.driver,
    lock: t.lock,
    markers: fixFirstMarker(t.markers),
    tourType: isOnePickUp,
  }))
}

const fixFirstMarker = (markers) => {
  markers.map((m) => {
    m.arrival_time = m.etaArrival
    m.finish_time = m.etaFinish
  })

  return markers
}
// re-order tours
export const reorderTours = (
  result,
  prevTours,
  tabIndex,
  simulatedTours,
  isOnePickUp
) => {
  if (result) {
    let changedTours = []
    const newTours = [...prevTours]
    const simTours = [...simulatedTours]
    let validatorCheck = true
    //drag and drop in the same table
    if (result.destination.droppableId === result.source.droppableId) {
      const { newMarkerList, validator } = reorderMultiple(
        prevTours[parseInt(result.destination.droppableId)].markers,
        result.source.index,
        result.destination.index,
        isOnePickUp === true ? tourType.ONE_PICK_UP : tourType.MULTI_PICK_UP
      )
      validatorCheck = validator
      newMarkerList.map((item, i) => {
        item.order = i
      })
      newTours[parseInt(result.destination.droppableId)].markers = newMarkerList
      changedTours.push(newTours[parseInt(result.destination.droppableId)])
    }
    //drag and drop between tables
    else {
      const { source, destination } = dragMultipleBetweenTables(
        prevTours[parseInt(result.source.droppableId)].markers,
        prevTours[parseInt(result.destination.droppableId)].markers,
        result.source.index,
        result.destination.index,
        isOnePickUp === true ? tourType.ONE_PICK_UP : tourType.MULTI_PICK_UP
      )
      newTours[parseInt(result.source.droppableId)].markers = source.map(
        (item, i) => {
          item.order = i
          return item
        }
      )
      newTours[parseInt(result.destination.droppableId)].markers = destination.map(
        (item, i) => {
          item.order = i
          return item
        }
      )
      changedTours.push(
        newTours[parseInt(result.source.droppableId)],
        newTours[parseInt(result.destination.droppableId)]
      )
    }
    simTours[tabIndex] = newTours
    //console.log('testing prev', prevTours)
    return { simTours: changedTours, validator: validatorCheck }
  }
}
export const reorderFromUnserved = (
  result,
  prevTours,
  tabIndex,
  simulatedTours,
  unservedTours,
  isOnePickUp
) => {
  if (result) {
    const newTours = [...prevTours]
    const simTours = [...simulatedTours]
    const changedTours = []

    const { newRresult, newUnservedTours, validator } = insertUnserved(
      prevTours[
        parseInt(
          result.destination.droppableId === '-1'
            ? result.source.droppableId
            : result.destination.droppableId
        )
      ].markers,
      unservedTours[tabIndex],
      result.source.index,
      result.destination.index,
      result.destination.droppableId === '-1' ? true : false,
      isOnePickUp === true ? tourType.ONE_PICK_UP : tourType.MULTI_PICK_UP
    )
    newTours[
      parseInt(
        result.destination.droppableId === '-1'
          ? result.source.droppableId
          : result.destination.droppableId
      )
    ].markers = newRresult.map((item, i) => {
      item.order = i
      return item
    })
    simTours[tabIndex] = newTours
    unservedTours[tabIndex] = newUnservedTours

    changedTours.push(
      newTours[
        parseInt(
          result.destination.droppableId === '-1'
            ? result.source.droppableId
            : result.destination.droppableId
        )
      ]
    )

    return {
      simTours: changedTours,
      newUnservedTours: unservedTours,
      validator,
    }
  }
  return true
}

const insertUnserved = (
  list,
  unservedTours,
  startIndex,
  endIndex,
  inverted,
  isOnePickUp
) => {
  let result = Array.from(list)
  const unservedArray = unservedTours ? Array.from(Object.keys(unservedTours)) : []
  //chauffeur to course
  if (inverted) {
    if (!result[startIndex].checked) {
      const [removed] = result.splice(startIndex, 1)
      const twinIndex = result.findIndex(
        (visit) =>
          visit.location_id === removed.location_id && visit.type !== removed.type
      )
      const [twin] = result.splice(twinIndex, 1)
      const pair = [removed, twin]
      unservedTours[removed.location_id] = {}
      pair.forEach((el) => {
        el.alert = 700
        el.reason = 'course placée manuellement'
        el.checked = false
        if (el.type === 'dropoff') {
          unservedTours[removed.location_id].dropOffVisit = el
          unservedTours[removed.location_id].dropOffVisit.type = '-'
        } else if (el.type === 'pickup') {
          unservedTours[removed.location_id].pickUpVisit = el
          unservedTours[removed.location_id].pickUpVisit.type = '-'
        }
      })
    } else {
      const listChecked = result.filter((rslt) => rslt.checked)
      // suprimmer twins dans tableau source
      listChecked.forEach((list) => {
        result.map((ele, pos) => {
          if (list.location_id === ele.location_id && list.type === ele.type) {
            result.splice(pos, 1)
          }
        })
      })

      // trouver twins dans tableau source
      const listCheckedTwin = listChecked
      result.forEach((el) => {
        if (elementExist(listChecked, el) == 1) {
          listCheckedTwin.push(el)
        }
      })

      // suprimmer twins dans tableau source
      listCheckedTwin.forEach((list) => {
        result.map((ele, pos) => {
          if (list.location_id === ele.location_id && list.type === ele.type) {
            result.splice(pos, 1)
          }
        })
      })

      listCheckedTwin.forEach((el) => {
        unservedTours[el.location_id] = {}
      })
      listCheckedTwin.forEach((el) => {
        el.alert = 700
        el.reason = 'course placée manuellement'
        el.checked = false
        if (el.type === 'dropoff') {
          unservedTours[el.location_id].dropOffVisit = el
          unservedTours[el.location_id].dropOffVisit.type = '-'
        } else if (el.type === 'pickup') {
          unservedTours[el.location_id].pickUpVisit = el
          unservedTours[el.location_id].pickUpVisit.type = '-'
        }
      })
    }

    //course to chauffeur
  } else {
    // check validation before remove unservedCourses in one pickup
    if (
      !validateLocationOnePickUp(
        result,
        unservedTours,
        isOnePickUp === tourType.ONE_PICK_UP
      )
    ) {
      return {
        newRresult: result,
        newUnservedTours: unservedTours,
        validator: false,
      }
    }
    const courseId = unservedArray[startIndex]
    if (
      !unservedTours[courseId].dropOffVisit.checked ||
      (unservedTours[courseId].dropOffVisit.checked &&
        getOrderChecked(unservedTours).length === 1)
    ) {
      if (
        !validateLocationOnePickUpNotChecked(
          result,
          unservedTours[courseId],
          isOnePickUp === tourType.ONE_PICK_UP
        )
      ) {
        return {
          newRresult: result,
          newUnservedTours: unservedTours,
          validator: false,
        }
      }
      unservedTours[courseId].dropOffVisit.type = 'dropoff'
      unservedTours[courseId].pickUpVisit.type = 'pickup'
      unservedTours[courseId].dropOffVisit.checked = false
      unservedTours[courseId].pickUpVisit.checked = false
      result.splice(endIndex, 0, unservedTours[courseId].pickUpVisit)
      result.splice(endIndex + 1, 0, unservedTours[courseId].dropOffVisit)
      delete unservedTours[courseId]
    } else {
      if (
        !validateLocationOnePickUp(
          result,
          unservedTours,
          isOnePickUp === tourType.ONE_PICK_UP
        )
      ) {
        return {
          newRresult: result,
          newUnservedTours: unservedTours,
          validator: false,
        }
      }
      getOrderChecked(unservedTours).map((code) => {
        unservedTours[code].dropOffVisit.type = 'dropoff'
        unservedTours[code].pickUpVisit.type = 'pickup'
        unservedTours[code].dropOffVisit.checked = false
        unservedTours[code].pickUpVisit.checked = false
        result.splice(endIndex, 0, unservedTours[code].pickUpVisit)
        result.splice(endIndex + 1, 0, unservedTours[code].dropOffVisit)
        delete unservedTours[code]
      })
    }
    if (isOnePickUp === tourType.ONE_PICK_UP) {
      result = sortMarkers(result, courseId)
    }
  }
  return {
    newRresult: result,
    newUnservedTours: unservedTours,
    validator: true,
  }

  //const [removed] = result.splice(startIndex, 1)

  //return result
}
const getOrderChecked = (unservedTours) => {
  const arrayOrderChecked = []
  Object.keys(unservedTours).map((code) => {
    if (unservedTours[code].dropOffVisit.checked) {
      arrayOrderChecked.push(code)
    }
  })
  return arrayOrderChecked
}
export const getPlanningData = (responseData, deliveryDate) => {
  const driversWithColors = []

  const tours = responseData.map((tour) => {
    if (!(tour.visits.length === 2 || tour.visits.length === 1)) {
      driversWithColors.push(tour.driver.id)
    }
    const path = [tour.path]
    const driver = {
      id: tour.driver.id,
      firstName: tour.driver.firstName,
      name: tour.driver.name,
      vehiculeType: tour.driver.vehicule?.vehiculeType ?? '-',
      competences: tour.driver.competences,
    }
    const tourType = tour.tourType

    let courseRank = 0

    const newMarkers = tour.visits.map((visit) => {
      courseRank = courseRank + (visit.type === 'dropoff' ? 1 : 0)
      return {
        ...visit,
        arrival_time: getHours(
          visit.arrival_time ? visit.arrival_time : visit.estimatedArrivalTime
        ),
        finish_time: getHours(
          visit.finish_time ? visit.finish_time : visit.estimatedDepartureTime
        ),
        day: getDays(
          visit.arrival_time ? visit.arrival_time : visit.estimatedArrivalTime
        ),
        estimatedArrivalTime: moment(
          visit.arrival_time ? visit.arrival_time : visit.estimatedArrivalTime
        ).format(),
        estimatedDepartureTime: moment(
          visit.finish_time ? visit.finish_time : visit.estimatedDepartureTime
        ).format(),
        windowEnd: visit.windowEnd ? visit.windowEnd : undefined,
        windowStart: visit.windowStart ? visit.windowStart : undefined,
        idleTime: visit.idleTime ? visit.idleTime : undefined,
        isManu: visit.isManu ? visit.isManu : undefined,
        montantPrestaHT: visit?.course?.montantPrestaHT,
        courseType: visit?.course?.courseType ? visit.course.courseType : undefined,
        salesChanel: visit?.course?.salesChanel,
        courseRank: visit.type === 'dropoff' ? courseRank : undefined,
        checked: false,
      }
    })
    const markers = appendMarkersObject(newMarkers)
    return {
      ...tour,
      driver,
      color:
        driversWithColors.indexOf(tour.driver.id) !== -1
          ? colorArray[driversWithColors.indexOf(tour.driver.id) % colorArray.length]
          : '#000000',
      distance: tour.distance,
      totalWeight: tour.tourWeight,
      tourDropoffWeight: tour?.tourDropoffWeight || 0,
      tourPickupWeight: tour?.tourPickupWeight || 0,
      totalVolume: tour.tourVolume,
      totalTime: tour.tourDuration,
      trajetTime: tour.tourTravel,
      stopsNumber: tour?.stopsNumber || 0,
      tourType,
      montantPrestataireHT: tour.tourPrice,
      markers,
      path,
      precision: 6,
      deliveryDate: deliveryDate,
      checkedAll: false,
      nbVisitChecked: 0,
      lock: false,
    }
  })
  return tours
}

export const parseSelectedCourses = (selectedCourses, shipments) => {
  const parsedShipments = {}
  selectedCourses.map((course) => {
    parsedShipments[course] = { pickUpVisit: {}, dropOffVisit: {} }
  })
  shipments.filter(
    ({
      code,
      reason,
      alert,
      type,
      pickUpStart,
      pickUpEnd,
      dropOffStart,
      dropOffEnd,
      volume,
      weight,
      montantPrestataireHT,
      manutention,
      lettreDeVoiture,
      adresseDepart,
      adresseArrivee,
      courseMetadata,
      commande: { canalVente },
    }) => {
      if (selectedCourses.includes(code)) {
        parsedShipments[code].pickUpVisit = {
          reason: reason ? reason : '-',
          alert: alert ? alert : '-',
          type: type ? type : '-',
          windowStart: pickUpStart,
          windowEnd: pickUpEnd,
          volume: volume ? volume : undefined,
          weight: weight ? weight : undefined,
          montantPrestaHT: montantPrestataireHT ? montantPrestataireHT : undefined,
          ldv: lettreDeVoiture?.code ? lettreDeVoiture?.code : undefined,
          lat: adresseDepart.latitude,
          lng: adresseDepart.longitude,
          identifier: adresseDepart.id,
          location_id: code,
          location_name: adresseDepart.address,
          isManu: manutention,
          courseType: courseMetadata?.courseType,
          salesChanel: {
            id: canalVente.id,
            name: canalVente.name,
            code: canalVente.code,
          },
          checked: false,
        }
        parsedShipments[code].dropOffVisit = {
          reason: reason ? reason : '-',
          alert: alert ? alert : '-',
          type: type ? type : '-',
          windowStart: dropOffStart,
          windowEnd: dropOffEnd,
          volume: volume ? volume : undefined,
          weight: weight ? weight : undefined,
          montantPrestaHT: montantPrestataireHT ? montantPrestataireHT : undefined,
          ldv: lettreDeVoiture?.code ? lettreDeVoiture?.code : undefined,
          lat: adresseArrivee.latitude,
          lng: adresseArrivee.longitude,
          identifier: adresseArrivee.id,
          location_id: code,
          location_name: adresseArrivee.address,
          isManu: manutention,
          courseType: courseMetadata?.courseType,
          salesChanel: {
            id: canalVente.id,
            name: canalVente.name,
            code: canalVente.code,
          },
          checked: false,
        }
      }
    }
  )
  return parsedShipments
}

export const appendMarkersObject = (mark) => {
  const markersArray = mark.map((visit) => {
    return {
      lat: visit?.cachedAddress?.lat,
      lng: visit?.cachedAddress?.lng,
      location_name: visit?.cachedAddress?.locationName,
      identifier: parseInt(visit?.cachedAddress?.code),
      location_id: visit?.course?.codeCourse,
      weight: visit?.course?.weight,
      volume: visit?.course?.volume,
      montantPrestaHT: visit?.montantPrestaHT,
      day: visit?.day,
      estimatedArrivalTime: visit?.estimatedArrivalTime,
      estimatedDepartureTime: visit?.estimatedDepartureTime,
      distance: visit?.distance,
      arrival_time: visit?.arrival_time,
      finish_time: visit?.finish_time,
      createdAt: visit.createdAt,
      order: visit?.rank,
      type: visit?.type,
      windowEnd: visit?.windowEnd,
      windowStart: visit?.windowStart,
      idleTime: visit?.idleTime,
      isManu: visit?.isManu,
      ldv: visit?.ldv ? visit?.ldv : undefined,
      courseRank: visit.courseRank ? visit.courseRank : undefined,
      courseType: visit?.courseType ? visit?.courseType : undefined,
      salesChanel: visit?.salesChanel,
      keys: visit?.keys,
      nbColis: visit?.nbColis,
      prestaTime: visit?.prestaTime,
      receptionNumber: visit?.location_id,
      codePostale: visit?.codePostale,
      locationType: visit?.locationType,
      checked: visit?.checked,
      orderGiver: visit?.orderGiver,
      client: visit?.client,
      reason: visit?.reason,
    }
  })
  return markersArray
}

export const getParsedCourses = (planning, driverList) => {
  console.log(planning, 'before parse')
  return planning
    .filter((tour) => driverList.includes(tour.driver.id))
    .map((plan) => {
      console.log(plan, 'plan before parse')
      const visitMap = new Map()
      plan.markers.forEach((visit) => {
        if (visit.type != 'depotStart' && visit.type != 'depotEnd')
          visitMap.has(visit.location_id)
            ? visitMap.get(visit.location_id).push(visit)
            : visitMap.set(visit.location_id, [visit])
      })
      let course = []
      visitMap.forEach((val) => {
        console.log(val, 'val')
        course.push({
          id: val[0].location_id,
          tourId: plan.ldt,
          driverId: String(plan.driver.id),
          endLocation: getLocation('end', val),
          startLocation: getLocation('start', val),
          weight: val[0]?.weight ? val[0]?.weight : undefined,
          volume: val[0]?.volume ? val[0]?.volume : undefined,
          isManu: val[0]?.isManu,
          receptionDate: val[0].createdAt,
          montantPrestaHT: val[0]?.montantPrestaHT
            ? val[0].montantPrestaHT
            : undefined,
          earliestDeliveryDate: undefined,
          latestDeliveryDate: undefined,
          ldv: val[0]?.ldv ? val[0]?.ldv : undefined,
          ...getCreneaux(val),
          courseType: val[0]?.courseType ? val[0].courseType : undefined,
          salesChanel: val[0]?.salesChanel,
          keys: val[0]?.keys,
          nbColis: val[0]?.nbColis,
          prestaTime: val[0]?.prestaTime,
          receptionNumber: val[0]?.location_id,
          codePostale: val[0]?.codePostale,
          locationType: val[0]?.locationType,
          orderGiver: val[0]?.orderGiver,
          client: val[0]?.client,
          reason: val[0]?.reason,
        })
      })

      return course
    })
    .flat()
}

const getCreneaux = (val) => {
  const pickup = val.find((e) => e.type == 'pickup')
  const dropoff = val.find((e) => e.type == 'dropoff')
  const depotEnd = val.find((e) => e.type == 'depotEnd')
  const depotstart = val.find((e) => e.type == 'depotStart')

  if (depotstart)
    return {
      pickUpEnd: depotstart.windowEnd,
      pickupStart: depotstart.windowStart,
      dropoffStart: undefined,
      dropoffEnd: undefined,
    }
  if (depotEnd)
    return {
      pickUpEnd: undefined,
      pickUpStart: undefined,
      dropOffStart: depotEnd.windowStart,
      dropOffEnd: depotEnd.windowEnd,
    }

  return {
    pickUpEnd: pickup?.windowEnd,
    pickUpStart: pickup?.windowStart,
    dropOffStart: dropoff?.windowStart,
    dropOffEnd: dropoff?.windowEnd,
  }
}

const getLocation = (type, visitMap) => {
  let course
  switch (type) {
    case 'end': {
      course = visitMap
      if (course.length == 1)
        return {
          name: course[0].location_name,
          lat: course[0].lat,
          lng: course[0].lng,
          identifier: course[0].identifier,
        }

      course = visitMap.filter((e) => {
        return e.type == 'dropoff'
      })
      return {
        name: course[0].location_name,
        lat: course[0].lat,
        lng: course[0].lng,
        identifier: course[0].identifier,
      }
    }
    case 'start': {
      course = visitMap
      if (course.length == 1)
        return {
          name: course[0].location_name,
          lat: course[0].lat,
          lng: course[0].lng,
          identifier: course[0].identifier,
        }

      course = course.filter((e) => {
        return e.type == 'pickup'
      })
      return {
        name: course[0].location_name,
        lat: course[0].lat,
        lng: course[0].lng,
        identifier: course[0].identifier,
      }
    }
  }
}
export const fillEmptyDriverPlanning = (fleets, deliveryDate) => {
  const fleetArray = fleets.map((fleet) => {
    const plan = {
      lock: false,
      color: '#000000',
      totalVolume: 0,
      totalWeight: 0,
      tourDropoffWeight: 0,
      tourPickupWeight: 0,
      totalTime: 0,
      montantPrestataireHT: 0,
      trajetTime: 0,
      stopsNumber: 0,
      driver: {
        id: String(fleet.id),
        firstName: fleet.firstName,
        name: fleet.name,
        shiftStart: fleet.shiftStart.label,
        shiftEnd: fleet.shiftEnd.label,
        vehiculeType: fleet.vehiculeType,
        competences: fleet.competences,
      },
      //id: String(fleet.driver.id),
      distance: 0,
      precision: 6,
      deliveryDate,
      path: [''],
      markers: [
        {
          location_id: `${fleet.firstName}_start`,
          location_name: fleet.startLocation.name,
          arrival_time: fleet.shiftStart.label,
          finish_time: fleet.shiftStart.label,
          distance: 0,
          order: 0,
          type: 'depotStart',
          lat: fleet.startLocation.lat,
          lng: fleet.startLocation.lng,
          identifier: fleet.startLocation.identifier,
        },
        {
          location_id: `${fleet.firstName}_end`,
          location_name: fleet.endLocation.name,
          arrival_time: fleet.shiftEnd.label,
          finish_time: fleet.shiftEnd.label,
          distance: 0,
          order: 1,
          type: 'depotEnd',
          lat: fleet.endLocation.lat,
          lng: fleet.endLocation.lng,
          identifier: fleet.endLocation.identifier,
        },
      ],
    }

    return plan
  })
  return fleetArray
}

const addShiftStartPlanning = (plannings, fleets) => {
  return plannings.map((plan) => {
    const mappedFleetPlanning = fleets.find((fleet) => fleet.id == plan.driver.id)
    return {
      ...plan,
      driver: {
        ...plan.driver,
        shiftStart: mappedFleetPlanning
          ? mappedFleetPlanning.shiftStart.label
          : undefined,
        shiftEnd: mappedFleetPlanning
          ? mappedFleetPlanning.shiftEnd.label
          : undefined,
      },
    }
  })
}

export const newPlannings = (plannings, fleets, deliveryDate) => {
  const planningsNew = addShiftStartPlanning(plannings, fleets).flat(9)
  const newPlanning = planningsNew
  const emptyDriversPlannigns = fillEmptyDriverPlanning(fleets, deliveryDate)
  emptyDriversPlannigns.forEach((item) => {
    if (!plannings.find((plan) => plan.driver.id == item.driver.id)) {
      newPlanning.push(item)
    }
  })
  return newPlanning
}

export const parseCourseFromUnserved = (unserved) => {
  if (unserved) {
    let unservedChecked = []
    Object.keys(unserved).map((code) => {
      if (unserved[code].dropOffVisit.checked) unservedChecked.push(unserved[code])
    })
    return Object.values(unservedChecked).map((course) => {
      return {
        id: course.pickUpVisit.location_id,
        tourId: undefined,
        driverId: undefined,
        endLocation: {
          name: course.dropOffVisit.location_name,
          lat: course.dropOffVisit.lat,
          lng: course.dropOffVisit.lng,
          identifier: course.dropOffVisit.identifier,
        },
        startLocation: {
          name: course.pickUpVisit.location_name,
          lat: course.pickUpVisit.lat,
          lng: course.pickUpVisit.lng,
          identifier: course.pickUpVisit.identifier,
        },
        weight: course.pickUpVisit.weight ? course.pickUpVisit.weight : undefined,
        volume: course.pickUpVisit.volume ? course.pickUpVisit.volume : undefined,
        receptionDate: course.pickUpVisit.createdAt,
        montantPrestaHT: course.pickUpVisit.montantPrestaHT
          ? course.pickUpVisit.montantPrestaHT
          : undefined,
        isManu: course.pickUpVisit?.isManu,
        ldv: course.pickUpVisit?.ldv ? course.pickUpVisit?.ldv : undefined,
        pickUpEnd: course.pickUpVisit?.windowEnd,
        pickUpStart: course.pickUpVisit?.windowStart,
        dropOffStart: course.dropOffVisit?.windowStart, // dropOffstart end === creneau
        dropOffEnd: course.dropOffVisit?.windowEnd, // dropOffstart end === creneau
        secondDropOffStart: course.dropOffVisit?.secondWindowStart,
        secondDropOffEnd: course.dropOffVisit?.secondWindowEnd,
        courseType: course.dropOffVisit?.courseType,
        earliestDeliveryDate: undefined,
        latestDeliveryDate: undefined,
        salesChanel: {
          id: course.dropOffVisit?.salesChanel?.id,
          name: course.dropOffVisit?.salesChanel?.name,
          code: course.dropOffVisit?.salesChanel?.code,
        },
        keys: course.dropOffVisit?.keys,
        nbColis: course.dropOffVisit?.nbColis,
        prestaTime: course.dropOffVisit?.prestaTime,
        receptionNumber: course.dropOffVisit?.location_id,
        codePostale: course.dropOffVisit?.codePostale,
        locationType: course.dropOffVisit?.locationType,
        orderGiver: course.dropOffVisit?.orderGiver,
        client: course.dropOffVisit?.client,
        reason: course.dropOffVisit?.reason,
      }
    })
  }
}
export const setChecked = (unservedOrders) => {
  Object.keys(unservedOrders).map((code) => {
    unservedOrders[code].dropOffVisit.checked = false
    unservedOrders[code].pickUpVisit.checked = false
  })
  return unservedOrders
}

export const planningEmpty = (planning, tabIndex) => {
  return [planning][tabIndex]
    .map((i) => (i.markers.length === 2 ? true : false))
    .every((obj) => obj === true)
}

export const locationCheck = (unservedTours, planning, tabIndex, isOnePickUp) => {
  const checkIdentifierEqual = Object.values(unservedTours)
    .filter((obj) => obj.pickUpVisit.checked)
    .map((course) => {
      // return course.courseMetadata.courseType
      if (ClientDelivery.includes(course.pickUpVisit.courseType)) {
        return course.pickUpVisit.identifier
      } else if (ClientPickup.includes(course.pickUpVisit.courseType)) {
        return course.dropOffVisit.identifier
      } else {
        return course.pickUpVisit.identifier
      }
    })
    .every((v, i, arr) => v == arr[0])
  return !checkIdentifierEqual && planningEmpty(planning, tabIndex) && !isOnePickUp
}

export const getLocationFromUnservedCourses = (unservedTours) => {
  // get departure location from unservedTours and filter the
  const unservedToursArray = Object.values(unservedTours)
    .filter((obj) => obj.pickUpVisit.checked)
    .map((tour) => {
      if (ClientPickup.includes(tour.pickUpVisit.courseType)) {
        return {
          identifier: tour.dropOffVisit.identifier,
          lat: tour.dropOffVisit.lat,
          lng: tour.dropOffVisit.lng,
          name: tour.dropOffVisit.location_name,
        }
      } else {
        return {
          identifier: tour.pickUpVisit.identifier,
          lat: tour.pickUpVisit.lat,
          lng: tour.pickUpVisit.lng,
          name: tour.pickUpVisit.location_name,
        }
      }
    })
  return unservedToursArray
}

export const getFleetParse = (fleet, courses, isOnePickUp) => {
  if (isOnePickUp) {
    const fleetDriverArray = { ...fleet }
    const unservedCoursesArray = courses.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    )
    // set driver start and end to the same deprature address if its VRP system
    const identifier = parseInt(unservedCoursesArray.map((tour) => tour.identifier))
    const lat = parseFloat(unservedCoursesArray.map((tour) => tour.lat))
    const lng = parseFloat(unservedCoursesArray.map((tour) => tour.lng))
    const name = unservedCoursesArray.map((tour) => tour.name).toString()

    fleetDriverArray.drivers = fleetDriverArray.drivers.map((driver) => {
      return {
        ...driver,
        startLocation: {
          identifier: identifier,
          lat: lat,
          lng: lng,
          name: name,
        },
        endLocation: {
          identifier: identifier,
          lat: lat,
          lng: lng,
          name: name,
        },
      }
    })
    return fleetDriverArray
  } else {
    return fleet
  }
}

export const getRoutificMessageError = (helperCode, helperVar) => {
  if (
    helperCode == ROUTIFIC_ERROR_ENUM.ERR_INVALID_LAT_LNG_NETWORK.code ||
    helperCode == ROUTIFIC_ERROR_ENUM.ERR_UNKNOWN.code ||
    helperCode == ROUTIFIC_ERROR_ENUM.ERR_MISSING_DRIVER_LAT_LNG.code ||
    helperCode == ROUTIFIC_ERROR_ENUM.ERR_MULTIPLE_LOCATION_COURSES.code ||
    helperCode == ROUTIFIC_ERROR_ENUM.ERR_OUT_OF_INTERVALL_COURSES.code
  ) {
    return { message: ROUTIFIC_ERROR_ENUM[helperCode].message }
  } else {
    return {
      message: ROUTIFIC_ERROR_ENUM[helperCode].message.concat(
        ', Merci de modifier cette course via : '
      ),
      editUrl: `${cornerUrl}edit/${helperVar}`,
    }
  }
}

// getting tourType from existing tours
export const getTourTypeFromPlanning = (planning, tabIndex) => {
  const tourTypeArray = [planning][tabIndex]
    .map((i) => i.tourType)
    .filter((obj) => obj)
  // check if tours have the same tourType
  if (tourTypeArray.every((obj) => obj === tourType.ONE_PICK_UP)) {
    return tourType.ONE_PICK_UP
  } else if (tourTypeArray.every((obj) => obj === tourType.MULTI_PICK_UP)) {
    return tourType.MULTI_PICK_UP
  }
}

// if planning is empty set tourType to initiale state, otherwise set tourType to existing value
export const existingPlanning = (planning, tabIndex, isOnePickUp) => {
  const planningLength = [planning][tabIndex]
    .map((i) => i.markers.length)
    .some((obj) => obj > 2)
  if (planningLength) {
    if (getTourTypeFromPlanning(planning, tabIndex) === tourType.ONE_PICK_UP) {
      return true
    } else if (
      getTourTypeFromPlanning(planning, tabIndex) === tourType.MULTI_PICK_UP
    )
      return false
  }
  return !isOnePickUp
}

// validate order
const validateOrderCheck = (newResult) => {
  const markerType = newResult
    .filter((obj) => obj.type == 'dropoff' || obj.type == 'pickup')
    .map((marker) => {
      return marker.type
    })
  // sort markers and verify if it's ordred as markers from payload or no
  const markerTypeValidation = []
  for (let i = 0; i < markerType.length / 2 - 1; i++) {
    markerTypeValidation.push(markerType[i] == markerType[i + 1])
  }
  return markerTypeValidation.every((obj) => obj)
}

// get identifier From exsiting planning
const locationFromPlanning = (result) => {
  return result
    .filter((obj) => obj.type == 'dropoff' || obj.type == 'pickup')
    .map((marker) => {
      if (
        (!ClientDelivery.includes(marker.courseType) &&
          !ClientPickup.includes(marker.courseType) &&
          marker.type === 'pickup') ||
        (ClientDelivery.includes(marker.courseType) && marker.type === 'pickup') ||
        (ClientPickup.includes(marker.courseType) && marker.type === 'dropoff')
      ) {
        return marker.identifier
      }
    })
    .filter((obj) => obj)
}

export const validateLocationOnePickUpNotChecked = (result, course, isOnePickUp) => {
  // if driver have a planning comparing unservedCourses and planning identifiers
  if (isOnePickUp) {
    let identifierFromUnserved
    if (ClientDelivery.includes(course.pickUpVisit.courseType)) {
      identifierFromUnserved = course.pickUpVisit.identifier
    } else if (ClientPickup.includes(course.pickUpVisit.courseType)) {
      identifierFromUnserved = course.dropOffVisit.identifier
    } else {
      identifierFromUnserved = course.pickUpVisit.identifier
    }
    const identifier = [...locationFromPlanning(result), identifierFromUnserved]
    return identifier.every((v, i, arr) => v == arr[0])
  }
  return true
}

// validate identifier
export const validateLocationOnePickUp = (result, unservedTours, isOnePickUp) => {
  // if driver have a planning comparing unservedCourses and planning identifiers
  if (isOnePickUp) {
    const identifierFromUnserved = Object.values(unservedTours)
      .filter((obj) => obj.pickUpVisit.checked)
      .map((course) => {
        if (ClientDelivery.includes(course.pickUpVisit.courseType)) {
          return course.pickUpVisit.identifier
        } else if (ClientPickup.includes(course.pickUpVisit.courseType)) {
          return course.dropOffVisit.identifier
        } else {
          return course.pickUpVisit.identifier
        }
      })
      .filter((obj) => obj)
    const identifier = [...locationFromPlanning(result), ...identifierFromUnserved]
    return identifier.every((v, i, arr) => v == arr[0])
  }
  return true
}

// sort markers (pickup reverse dropoff)
const sortMarkers = (result, course) => {
  const pickupsMarkers = result.filter((obj) => obj.type === 'pickup')
  const dropoffMarkers = result.filter((obj) => obj.type === 'dropoff')
  let newResult
  if (course.type === 'pickup') {
    const pickupId = pickupsMarkers.map(({ location_id }) => location_id)
    dropoffMarkers.sort(
      (a, b) => pickupId.indexOf(a.location_id) - pickupId.indexOf(b.location_id)
    )
    newResult = [...dropoffMarkers, ...pickupsMarkers.reverse()]
  } else {
    const dropoffId = dropoffMarkers.map(({ location_id }) => location_id)
    pickupsMarkers.sort(
      (a, b) => dropoffId.indexOf(a.location_id) - dropoffId.indexOf(b.location_id)
    )
    newResult = [...dropoffMarkers.reverse(), ...pickupsMarkers]
  }
  result.splice(1, result.length - 2)
  for (const marker of newResult) {
    result.splice(1, 0, marker)
  }
  return result
}

export const reorderFromUnservedDeattribute = (
  positionsChauffeur,
  prevTours,
  tabIndex,
  simulatedTours,
  unservedTours
) => {
  const simTours = [...simulatedTours]

  const { newRresult, newUnservedTours } = insertUnservedDesattrib(
    positionsChauffeur,
    prevTours,
    unservedTours[tabIndex]
  )
  simTours[tabIndex] = newRresult

  unservedTours[tabIndex] = newUnservedTours
  return {
    simTours,
    newUnservedTours: unservedTours,
  }
}

const insertUnservedDesattrib = (positionsChauffeur, tournees, unservedTours) => {
  positionsChauffeur.map((position) => {
    let result = tournees[position].markers
    const courses = result.slice(1, result.length - 1)
    courses.forEach((el) => {
      unservedTours[el.location_id] = {}
    })

    courses.forEach((el) => {
      el.alert = 700
      el.reason = 'course placée manuellement'
      el.checked = false
      if (el.type === 'dropoff') {
        unservedTours[el.location_id].dropOffVisit = el
      } else if (el.type === 'pickup') {
        unservedTours[el.location_id].pickUpVisit = el
      }
    })
    tournees[position].markers = [result[0], result[result.length - 1]].map(
      (item, i) => {
        item.order = i
        return item
      }
    )
  })

  return {
    newRresult: tournees,
    newUnservedTours: unservedTours,
  }
}

export const fleetParseObject = (driversOriginal, deliveryDate) => {
  const drivers = []
  driversOriginal.map(
    ({
      id,
      firstname,
      lastname,
      adresseDepart,
      adresseArrivee,
      operationalHours,
      vehiculeId,
      competences,
    }) =>
      drivers.push({
        firstName: firstname,
        name: lastname,
        id: String(id),
        startLocation: {
          name: adresseDepart.address,
          lat: adresseDepart.latitude,
          lng: adresseDepart.longitude,
          identifier: adresseDepart.id,
        },
        endLocation: {
          name: adresseArrivee.address,
          lat: adresseArrivee.latitude,
          lng: adresseArrivee.longitude,
          identifier: adresseArrivee.id,
        },
        shiftStart: {
          hour: operationalHours.openTime.split(':')[0],
          minute: operationalHours.openTime.split(':')[1],
          label: operationalHours.openTime,
        },
        shiftEnd: {
          hour: operationalHours.closeTime.split(':')[0],
          minute: operationalHours.closeTime.split(':')[1],
          label: operationalHours.closeTime,
        },
        volume: vehiculeId?.vehicule_category?.volumeMax || null,
        weight: vehiculeId?.vehicule_category?.weight || null,
        vehiculeType: vehiculeId?.vehicule_category?.type || '-',
        competences: competences,
        breakStart: 0,
        breakEnd: 0,
      })
  )
  return {
    drivers,
    maxVisitLatenes: 15,
    maxOvertime: 60,
    minVehicule: false,
    shortestDistance: false,
    balence: undefined,
    balenceCoef: 1,
    deliveryDate,
  }
}

export const parseCoursesOptimisationObject = (courses) => {
  const parsedShipments = {}
  courses.forEach((course) => {
    parsedShipments[course.receptionNumber] = { pickUpVisit: {}, dropOffVisit: {} }
  })
  courses.filter(
    ({
      receptionNumber,
      type,
      pickUpStart,
      pickUpEnd,
      dropOffStart,
      dropOffEnd,
      weight,
      volume,
      count,
      prestaTime,
      adresseArrivee,
      price,
      adresseDepart,
      keys,
      orderGiver,
      client,
      reason,
      secondDropOffStart,
      secondDropOffEnd,
    }) => {
      parsedShipments[receptionNumber].pickUpVisit = {
        windowStart: pickUpStart,
        windowEnd: pickUpEnd,
        volume: volume ? volume : undefined,
        weight: weight ? weight : undefined,
        montantPrestaHT: price ? price : undefined,
        lat: adresseDepart.latitude,
        lng: adresseDepart.longitude,
        identifier: adresseDepart.id,
        location_id: String(receptionNumber),
        location_name: adresseDepart.address,
        isManu: false,
        courseType: type,
        keys,
        nbColis: count,
        prestaTime,
        checked: false,
        orderGiver,
        client,
        reason,
      }
      parsedShipments[receptionNumber].dropOffVisit = {
        windowStart: dropOffStart,
        windowEnd: dropOffEnd,
        secondWindowStart: secondDropOffStart,
        secondWindowEnd: secondDropOffEnd,
        volume: volume,
        weight: weight,
        montantPrestaHT: price,
        lat: adresseArrivee.latitude,
        lng: adresseArrivee.longitude,
        identifier: adresseArrivee.id,
        location_id: String(receptionNumber),
        location_name: adresseArrivee.address,
        isManu: false,
        courseType: type,
        keys,
        nbColis: count,
        prestaTime,
        codePostale: adresseArrivee?.postalCode,
        locationType: adresseArrivee?.locationType,
        checked: false,
        orderGiver,
        client,
        reason,
      }
    }
  )
  return parsedShipments
}

export const parseFilterTournee = (filter) => {
  const parseFilter = {}
  Object.keys(filter).map((el) => {
    parseFilter[el] = filter[el].map((fil) => {
      return {
        code: fil,
        name: fil + '',
      }
    })
  })
  return parseFilter
}

export const ajusterChevauchementsMarqueurs = (markers) => {
  const OFFSET = 0.0002
  const positions = new Set()
  markers.forEach((marker, index) => {
    const position = marker.position
    let newPosition = position
    if (marker.infoText.props?.type !== 'pickup') {
      while (positions.has(`${newPosition.lat}_${newPosition.lng}`)) {
        newPosition = {
          lat: newPosition.lat + OFFSET,
          lng: newPosition.lng + OFFSET,
        }
      }

      if (newPosition !== position) {
        marker.position = newPosition
      }

      positions.add(`${newPosition.lat}_${newPosition.lng}`)
    }
  })

  return markers
}

export const parseReceptionNumber = (receptionNumber) => {
  const lastIndexOf = receptionNumber.lastIndexOf('-')

  return lastIndexOf != -1
    ? receptionNumber.substring(0, lastIndexOf)
    : receptionNumber
}

export const reorderFromUnservedInversionTournee = (
  positionsChauffeur,
  prevTours
) => {
  const simTours = [...prevTours]
  const changedTours = []
  let tourneeA = simTours[positionsChauffeur[0]].markers
  const coursesA = tourneeA.slice(1, tourneeA.length - 1)

  let tourneeB = simTours[positionsChauffeur[1]].markers
  const coursesB = tourneeB.slice(1, tourneeB.length - 1)

  simTours[positionsChauffeur[0]].markers = [
    tourneeA[0],
    ...coursesB,
    tourneeA[tourneeA.length - 1],
  ].map((item, i) => {
    item.order = i
    return item
  })

  simTours[positionsChauffeur[1]].markers = [
    tourneeB[0],
    ...coursesA,
    tourneeB[tourneeB.length - 1],
  ].map((item, i) => {
    item.order = i
    return item
  })

  changedTours.push(simTours[positionsChauffeur[0]], simTours[positionsChauffeur[1]])

  return {
    simTours: changedTours,
  }
}

export const reorderFromUnservedInversionOrderTournee = (
  positionsChauffeur,
  prevTours
) => {
  const simTours = [...prevTours]
  const changedTours = []

  positionsChauffeur.map((pos) => {
    const tournee = simTours[pos].markers
    const courses = tournee.slice(1, tournee.length - 1)

    const coursesPickup = courses.slice(0, courses.length / 2)
    const coursesDropOff = courses.slice(courses.length / 2, courses.length)
    const coursesReverse = [...coursesPickup, ...coursesDropOff.reverse()]

    simTours[pos].markers = [
      tournee[0],
      ...coursesReverse,
      tournee[tournee.length - 1],
    ].map((item, i) => {
      item.order = i
      return item
    })
    changedTours.push(simTours[pos])
  })

  return {
    simTours: changedTours,
  }
}
